import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import { LeftSideBar } from "../Components/LeftSideBar";
import { MainPage } from "../Components/MainPage";
import { RightSideBar } from "../Components/RightSideBar";
import { motion } from 'framer-motion';
import Onboarding from "../Components/onBoarding";
import { useEffect, useState } from "react";
import SearchIcon from '../asset/search.png';
import SendIcon from '../asset/send-circle.png';
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketsFailure, fetchMarketsStart, fetchMarketSuccess } from "../Redux/marketRedux";
import { FetchGames, GetAllMarkets } from "../Redux/apiCalls";
import { ToastContainer, toast } from 'react-toastify';
import { Menu } from "../Components/Menu";
import { fetchGamesFailure, fetchGamesStart, fetchGamesSuccess } from "../Redux/gamesRedux";
import { useNavigate } from 'react-router-dom';
import PredictionBadge from "../Components/PredictionNotificationBadge";
import ChangePasswordModal from "../Components/Modals/ChangePassword";
import { useModal } from "../Components/Modals/ModalContext";
import PredictionModal from "../Components/Modals/PredictionModal";
import BuyPoints from "../Components/Modals/BuyPoints";

export default function LayoutPage() {
    const [games, setGames] = useState<any>([]);
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);

    const game = useSelector((state: any) => state.game.games);
    const boarding = useSelector((state: any) => state.user.onBoarding);
    const consent = useSelector((state: any) => state.user.consent);

    const dispatch = useDispatch();
    const navigate = useNavigate();
  const { openConsentModal } = useModal();



    const handleTabs = async () => {
        try {
            dispatch(fetchMarketsStart());

            const market = await GetAllMarkets();
            if (market.info.length > 0) {
                dispatch(fetchMarketSuccess(market.info))
            }
            else {
                dispatch(fetchMarketsFailure());
            }

        } catch (error) {
            dispatch(fetchMarketsFailure());

        }
    }


    useEffect(() => {
        handleTabs();
        setGames(game);
        if(!consent) {
            openConsentModal()
        }

        // const savedRoute = 'loading-data';
        // if (savedRoute) {
        //     navigate(savedRoute);
        // }


    }, []);


    const handleKeyDown = (e: any) => {
        if (e.key === 'Backspace') {
            // Clear the input field when the Backspace key is pressed and the value is empty
            setSearchValue('');
            setGames(game);
            return
        }

        if (e.key === 'Enter') {
            const SR = HandleSearchFilter();
            setGames(SR)
        }
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };



    const HandleSearchFilter = () => {
        setIsSearching(true);

        setTimeout(() => {
            const Sresult = games.filter((item: any) => {
                const searchValueLower = searchValue.toLowerCase();
                return (
                    item.homeName.toLowerCase().includes(searchValueLower) ||
                    item.awayName.toLowerCase().includes(searchValueLower)
                );
            });
            setIsSearching(false);

            if (Sresult.length > 0) {
                setGames(Sresult)
                setIsSearching(false);
            }
            else {
                toast.warn("No game found");
                setSearchValue('');
                setGames(game)
                setIsSearching(false);
            }
        }, 3000);
    }

    return (
        <div className="w-full h-screen">
            {/* {showSidebar && <div className="modal-overlay absolute w-full h-full bg-[#000000] opacity-75 z-10 md:hidden" onClick={toggleSidebar}></div>} */}
            <Header toggleSidebar={toggleSidebar} />

            {showSidebar && (
                <div className="absolute w-full h-full bg-[#000000] opacity-75 z-10 md:hidden" onClick={toggleSidebar}></div>
            )}
            {showSidebar && (

                <motion.div
                    initial={{ x: '100%' }} // Start off-screen for mobile
                    animate={{ x: showSidebar ? 0 : '100%' }} // Slide in/out smoothly
                    exit={{ x: '100%' }} // Closing animation for mobile
                    transition={{ duration: 0.9 }} // Customize animation duration
                    style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        width: '90%', // Adjust sidebar width as needed
                        height: '100vh', // Full viewport height for mobile
                        zIndex: 20, // Ensure sidebar sits on top of other elements
                    }}
                >
                    <Menu toggleSidebar={toggleSidebar} />
                </motion.div>
            )}

            <ToastContainer autoClose={5000} />
            {boarding === false && <Onboarding />}
            <div className="flex">
                <LeftSideBar />

                <div className="flex-1 gap-3 mx-auto lg:ml-[20%] md:ml-[20%] lg:mr-[20%] md:mr-[20%]">

                    <PredictionBadge />
                    <ChangePasswordModal />
                    <PredictionModal />
                    <BuyPoints />

                    <MainPage games={games} setIsSearchBarVisible={setIsSearchBarVisible} isSearching={isSearching} />
                </div>

                <RightSideBar />
            </div>


            {!showSidebar && <div>
                {isSearchBarVisible && <div className="fixed bottom-0 w-full p-3 md:p-2 bg-[#000000] z-10" >

                    <div className="lg:ml-[25%] md:ml-[20%] lg:mr-[25%] md:mr-[20%]">
                        <div className="flex border-[1px] border-[#2A2A2A] border-radius rounded-lg p-2">
                            <div className="relative w-full mr-4">
                                <input
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);

                                        if (e.target.value === '') {
                                            setGames(game)
                                        }
                                    }}
                                    type="text"
                                    onKeyDown={handleKeyDown}
                                    placeholder="Search Team..."
                                    className="w-full h-12 pl-16 pr-12  bg-[#0B0B0B] text-[#545454] border-radius placeholder-[#545454] border-[1px] border-[#2A2A2A] border-radius focus:outline-none text-sm"
                                />

                                <div className="absolute inset-y-0 left-0 flex items-center pl-4">

                                    <div className="w-6 h-6 md:w-8 md:h-8 text-white mr-1">
                                        <img src={SearchIcon} alt="Icon" className="text-[#00FF87]" />
                                    </div>
                                </div>
                            </div>

                            <div className=" inset-y-0 right-0 flex items-center pr-2 md:pr-4">

                                <div onClick={() => {
                                    const SR = HandleSearchFilter();
                                    setGames(SR)
                                }} className="w-8 h-8 md:w-10 md:h-10 text-white mr-1 cursor-pointer">
                                    <img src={SendIcon} alt="Icon" className="text-[#00FF87]" />
                                </div>
                            </div>
                        </div>



                        <div className="flex justify-center items-center w-full mt-3">
                            <span style={{ textAlign: 'center' }} className="text-[#6F6F6F] text-[0.4rem] md:text-[0.7rem] font-[200] font-[Roboto] mb-2">
                                {/* Betracle AI predictions are for entertainment purposes only and should not be used for betting or gambling <br /> with real money, as accuracy is not guaranteed */}
                                AI predictions can be wrong, use responsibly
                            </span>
                        </div>
                    </div>


                </div>}
            </div>}

        </div>
    )
}
