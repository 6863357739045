import React, { useState, useEffect } from 'react';

const PredictionBadge = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50 mr-4">

                {/* <div className="modal-overlay absolute top-[64px] w-full h-full bg-[#000000] opacity-50">
                    
                </div> */}
                
                <div className="modal-container top-[40px]  w-[72%] md:w-[25%]  absolute bg-transparent glass-card mx-auto border-radius shadow-lg z-50 overflow-y-auto account-modal border-[1px] border-[#2A2A2A] border-radius" style={{ boxShadow: '5px 10px 10px rgba(0, 0, 0, 0.758)' }}
                >
                    
                    <div className="modal-content py-4 text-left px-6">
                        {/* <div className='relative bg-[#00170C] w-12 h-12 flex items-center justify-center glass-card' style={{ borderRadius: '50%'}}>
                            <h1 className='text-[#D9D9D9] text-[0.7rem] md:text-[1.5rem]'>1</h1>
                        </div> */}

                       

                        <div className='mb-2'>
                            <h1 className='text-[#00FF87] font-extrabold font-[Orbitron] mb-2 text-[0.7rem] md:text-[0.9rem]'>Welcome back!</h1>
                            <p className='text-[0.6rem] md:text-[0.8rem] text-[#A2A1A1]'>
                            You will receive 3 prediction points every day!
                            </p>
                        </div>

                        
                    </div>
                </div>
            </div>
  );
};

export default PredictionBadge;
