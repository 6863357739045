import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import PredictionImage from '../asset/prediction.png';
import HistoryImage from '../asset/history.png';
import RewardImage from '../asset/rewards.png';
import { GameCard } from './GameCard';
import { motion } from 'framer-motion';
import SearchIcon from '../asset/search.png';
import SendIcon from '../asset/send-circle.png';
import { HistoryCard } from './HistoryCard';
import { RewardCard } from './RewardCard';
import { GetPredictionHistory } from '../Redux/apiCalls';
import { PleaseWait } from './PleaseWait';
import { LOCK_FEATURE } from '../Utils/utils';

export const GameSection: React.FC<any> = ({ data, searchResult, searchValue, setSearchResult, loading, setIsSearchBarVisible, isSearching }) => {
    const [activeTab, setActiveTab] = useState('Predictions');
    const [predictionHistory, setPredictionHistory] = useState([]);

    // const game = useSelector((state: any) => state.game.games);
    const userState = useSelector((state: any) => state.user.currentUser);

    const handleTabClick = (tabId: any) => {
        setActiveTab(tabId);
        // window.scrollTo(0, 0);
    };

    useEffect(() => {
        // Automatically reset scroll position when the tab changes
        window.scrollTo(0, 0);
    }, [activeTab]);

    const GetHistory = async () => {
        try {
            const info = await GetPredictionHistory(userState.user.id);
            setPredictionHistory(info.history)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        window.onscroll = function () { myFunction() };

        var header: any = document.getElementById("myHeader");
        var sticky = header.offsetTop;

        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    }, [])


    return (
        <div className="mb-4 mt-0 md:mt-10" >
            <div className="header m-2 md:m-0 md:p-0" id="myHeader">
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}

                >
                    <ul className="top-0 w-full z-10 flex flex-wrap -mb-px text-sm font-medium text-center bg-[#000000] mx-0 md:mx-0 border-[1px] border-[#2A2A2A] border-radius p-3 md:p-0" id="myTab" role="tablist">
                        <li className="md:mr-5" role="presentation">
                            <button
                                className={`inline-block m-0 md:m-2 p-3 ${activeTab === 'Predictions' ? 'bg-[#00170C] text-[#00FF87] text-[rgba(0, 0, 0, 0.3] font-[500]' : 'border-transparent text-[#545454] font-[300]'} rounded-lg text-[0.6rem] md:text-[0.9rem] flex items-center md:w-[150px]`}
                                onClick={() => {
                                    handleTabClick('Predictions');
                                    setIsSearchBarVisible(true);
                                }}
                                role="tab"
                                aria-controls="Predictions"
                                aria-selected={activeTab === 'Predictions'}
                            >
                                <img src={PredictionImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-2" />
                                Predictions
                            </button>
                        </li>
                        <li className="md:mr-5" role="presentation">
                            <button
                                className={`inline-block m-0 md:m-2 p-3 ${activeTab === 'History' ? 'bg-[#00170C] text-[#00FF87] text-[rgba(0, 0, 0, 0.3] font-[500] font-[500]' : 'border-transparent text-[#545454] font-[300]'} rounded-lg text-[0.6rem] md:text-[0.9rem] flex items-center md:w-[150px]`}
                                onClick={() => {
                                    setIsSearchBarVisible(false);
                                    handleTabClick('History')
                                    // searchValue('');
                                    // setSearchResult(game);
                                    GetHistory();
                                }}
                                role="tab"
                                aria-controls="History"
                                aria-selected={activeTab === 'History'}
                            >
                                <img src={HistoryImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-2" />
                                History
                            </button>
                        </li>

                        {LOCK_FEATURE &&<li className="md:mr-5" role="presentation">
                            <button
                                className={`inline-block m-0 md:m-2 p-3 ${activeTab === 'Rewards' ? 'bg-[#00170C] text-[#00FF87] text-[rgba(0, 0, 0, 0.3] font-[500] font-[500]' : 'border-transparent text-[#545454] font-[300]'} rounded-lg text-[0.6rem] md:text-[0.9rem] flex items-center md:w-[150px]`}
                                onClick={() => {
                                    handleTabClick('Rewards');
                                    setIsSearchBarVisible(false);
                                }}
                                role="tab"
                                aria-controls="Rewards"
                                aria-selected={activeTab === 'Rewards'}
                            >
                                <img src={RewardImage} alt="Icon" className="w-4 h-4 md:w-6 md:h-6 mr-2" />
                                Rewards
                            </button>
                        </li>}
                    </ul>
                </motion.div>
            </div>

            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2.5 }}

            >

                <div className='border-[#2A2A2A] border-radius mt-3 md:mt-10 mr-4 md:mr-0 ml-4 md:ml-0 mb-4 md:mb-0' id="myTabContent" style={{ position: 'relative', zIndex: 1 }}>
                    <div className={`p-0 border-radius ${activeTab === 'Predictions' ? 'bg-gray-50 dark:bg-[#000000]' : 'hidden'}`} role="tabpanel" aria-labelledby="profile-tab" >

                        {isSearching && <PleaseWait text={'Please wait...'} />}

                        {
                            data?.map((item: any, i: any) => (
                                <GameCard game={item} key={i} history={predictionHistory}/>
                            ))
                        }


                    </div>

                    <div className={`p-0 border-radius ${activeTab === 'History' ? 'bg-gray-50 dark:bg-[#000000]' : 'hidden'}`} role="tabpanel" aria-labelledby="dashboard-tab">
                        {
                            predictionHistory?.map((item: any, i: any) => (
                                <HistoryCard history={item} key={i} />
                            ))
                        }

                        {
                            predictionHistory?.length === 0 && <PleaseWait text={'No history found.'} />
                        }

                    </div>


                    <div className={`p-0 border-radius ${activeTab === 'Rewards' ? 'bg-gray-50 dark:bg-[#000000]' : 'hidden'}`} role="tabpanel" aria-labelledby="dashboard-tab">
                        <RewardCard />
                    </div>

                    <div style={{ height: '100px' }}></div>
                </div>

            </motion.div>
        </div>
    )
}
